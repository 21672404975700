@import "~@angular/flex-layout/mq";
@import "/src/vars.scss";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    color: $blackColor;
    background: #eeeeee;
}

a {
    color: $primaryColor;
}


mat-toolbar {
    .mat-tab-link {
        height: 64px !important;
        font-size: 16px;
        min-width: 0;

        @include layout-bp(xs) {
            height: 56px !important;
        }
    }
}

.mat-ink-bar {
    height: 4px !important;
}

app-main-toolbar .mat-ink-bar {
    height: 100% !important;
    background-color: $accentColor  !important;
}

.mat-tab-label-active:not(.mat-tab-disabled) {
    opacity: 1 !important;
}

.mat-primary .mat-tab-label-active {
    color: $primaryColor  !important;
}

.mat-accent .mat-tab-label-active {
    color: $accentColor  !important;
}

.mat-tab-nav-bar.mat-tab-header {
    border-bottom: 0 !important;
}

.big-button {
    zoom: 1.5;
}

.mat-form-field.big-form-field {
    zoom: 1.5;
}

.mat-dialog-actions {
    margin-bottom: -12px !important;
}

.image-processing-steps {

    .mat-expansion-panel {
        box-shadow: none !important
    }

    .mat-expansion-panel-header[aria-disabled=true] {
        color: inherit !important;
    }

    .mat-expansion-panel-header[aria-disabled=true] {
        color: inherit !important;
    }

    .card-list .mat-list-item-content {
        flex-grow: 1;
    }
}

.mat-drawer {
    width: 300px;
}

.text-monospace {
    font-family: monospace;
}

.white.mat-progress-spinner circle,
.white.mat-spinner circle {
    stroke: white !important;
}