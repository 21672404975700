@import "~@angular/material/theming";
@import "/src/vars.scss";

$custom-typography: mat-typography-config($font-family: "Roboto",
    );

@include mat-core($custom-typography);

/*
http://mcg.mbitson.com/#!?mcgpalette0=%233d85c6&mcgpalette1=%230b5394&mcgpalette2=%23c62828&themename=mcgtheme
*/

/* For use in src/lib/core/theming/_palette.scss */
/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette0: (
    50 : #e8f0f8,
    100 : #c5daee,
    200 : #9ec2e3,
    300 : #77aad7,
    400 : #5a97cf,
    500 : #3d85c6,
    600 : #377dc0,
    700 : #2f72b9,
    800 : #2768b1,
    900 : #1a55a4,
    A100 : #dae9ff,
    A200 : #a7caff,
    A400 : #74abff,
    A700 : #5b9cff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-mcgpalette1: (
    50 : #e2eaf2,
    100 : #b6cbdf,
    200 : #85a9ca,
    300 : #5487b4,
    400 : #306da4,
    500 : #0b5394,
    600 : #0a4c8c,
    700 : #084281,
    800 : #063977,
    900 : #032965,
    A100 : #95b6ff,
    A200 : #6294ff,
    A400 : #2f71ff,
    A700 : #155fff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-mcgpalette2: (
    50 : #f8e5e5,
    100 : #eebfbf,
    200 : #e39494,
    300 : #d76969,
    400 : #cf4848,
    500 : #c62828,
    600 : #c02424,
    700 : #b91e1e,
    800 : #b11818,
    900 : #a40f0f,
    A100 : #ffd4d4,
    A200 : #ffa1a1,
    A400 : #ff6e6e,
    A700 : #ff5454,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);





/* For use in src/lib/core/theming/_palette.scss */
$md-myaccent: $md-mcgpalette0;

$md-myprimary: $md-mcgpalette1;

$md-mywarn: $md-mcgpalette2;

$my-app-primary: mat-palette($md-myprimary);
$my-app-accent: mat-palette($md-myaccent);
$my-app-warn: mat-palette($md-mywarn);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include angular-material-theme($my-app-theme);